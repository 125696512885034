<template>
  <div >
    <b-popover :target="target" custom-class="note-popover" placement="bottom" ref="popover">
      <b-row class="justify-content-between margin-title-row">
        <span class="openSans-300 fz-15">{{ $t('knowledge.ask.notes.popup.title') }}</span>
        <img class="cursor-pointer" @click="closeEditor" :src="'/assets/images/icon_close_popover.svg'"/>
      </b-row>
      <b-form-textarea class="openSans-100 fz-15 input-margin note-popover-textarea"
          v-model="editText"
          :disabled="!editMode"
          :placeholder="$t('knowledge.ask.notes.popup.inputPlaceholder')">
      </b-form-textarea>
      <div class="d-flex justify-content-center margin-button-note-popover">
        <div v-if="!editMode">
          <button type="button" class="btn openSans-600 fz-12 ta-center button-secondary btn-secondary btn-md mr-3" @click="deleteNote">
            {{ $t('knowledge.ask.notes.popup.deleteButton') }}
          </button>
          <button type="button" class="btn openSans-600 fz-12 ta-center button-secondary btn-secondary btn-md" @click="edit">
            {{ $t('knowledge.ask.notes.popup.editButton') }}
          </button>
        </div>
        <button v-else type="button" class="btn openSans-600 fz-12 ta-center button-secondary btn-secondary btn-md" @click="save">
          {{ $t('knowledge.ask.notes.popup.saveButton') }}
        </button>
      </div>
    </b-popover>
  </div>
</template>

<script>
import AskService from "@/modules/knowledge/services/AskService.js"

export default {
  name: 'NotePopup',
  props: {
    target: String,
    type: String,
    logId: String,
    inputId: String,
    responseId: String,
    noteData: Object,
    callback: Function
  },
  data() {
    return {
      data: {
        text: ""
      },
      editText: "",
      editMode: true
    }
  },
  methods: {
    edit() {
      this.editMode = true
    },
    save() {
      if (this.data.text != this.editText) {
        AskService.storeNote(this.editText, this.type, this.logId, this.inputId, this.responseId).then(() => {
          this.editMode = false
          this.callback('icon-bookmark-active')
        })
      }
    },
    deleteNote() {
      this.editMode = false
      AskService.deleteNote(this.inputId, this.type).then(() => {
        this.data.text = ""
        this.editText = ""
        this.editMode = true
        this.callback('icon_bookmark')
        this.closeEditor()
      })
    },
    closeEditor() {
      this.$root.$emit('bv::hide::popover')
    },
    upadatePrivateData() {
      this.data.text = this.noteData.text
      this.editText = this.data.text
      this.editMode = this.editText == undefined
    }
  },
  watch: {
    noteData() {
      this.upadatePrivateData()
    }
  },
  created() {
    this.upadatePrivateData()
  }
}
</script>

<style scoped>

.margin-button-note-popover {
  margin: 18px 0px 0px 0px;
}

.margin-title-row {
  margin: 5px 0px;
}

.note-popover{
  min-width: 340px;
  min-height: 270px;
  height: auto;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}

.note-popover-textarea {
  border: transparent;
  padding: 10px 10px;
  min-height: 150px;
}

</style>

