<template>
  <div class="container-base section-container-padding bg-white">
    <div class="myproj-container settings-my-instances-tabs knowledge">
      <knowledge-tabs tab-name="ask" :resolve-route-outside="goTo"/>
      <b-row class="margin-knowledge-container">
        <b-col class="margin-cards ask-question-result-margin">
          <back-button :title="$t('general.backToAskAQuestion')"/>
          <b-row v-if="originalQuestion">
            <div class="text-left">
              <div class="mb-1">Showing response for <b-link class="answer-links">{{ question }}</b-link></div>
              <div class="mb-2">Search instead for <b-link class="answer-links" @click="searchOriginalQuestion">{{ originalQuestion }}</b-link></div>
            </div>
          </b-row>
          <b-row b-row v-if="responseArrived">
            <span class="openSans-100 fz-14">We found {{responses.length + passages.length}} responses</span>
            <span class="ask-question-margin-search openSans-100 fz-14 cursor-pointer d-none">
              <img src="/assets/images/icon_navbar_search.svg" alt="search"> Search within the results
            </span>
          </b-row>
          <b-row :class="{'mobile-preloader': is_mobile}" v-else>
            <b-col class="wait-response-spinner">
              <b-spinner class="m-0" variant="success" label="Spinning"></b-spinner>
            </b-col>
          </b-row>
          <div v-if="allResponses.length == 0">
            <!-- display questions first -->
            <span v-for="(response , index) in responses" :key="index">
              <ask-question-card :data="response" :log-id="logId"></ask-question-card>
            </span>
          </div>
          <div v-else>
            <!-- display questions and passages when the passages are received -->
            <span v-for="(response , index) in allResponses" :key="index">
              <ask-passage-card v-if="response.passages" :data="response" :log-id="logId" :text-searched="question"></ask-passage-card>
              <ask-question-card v-else :data="response" :log-id="logId" :text-searched="question"></ask-question-card>
            </span>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import tools from "@/modules/common/utils/tools.js"
import AskService from "@/modules/knowledge/services/AskService.js"
import KnowledgeTabs from "@/modules/knowledge/components/KnowledgeTabs.vue"
import AskQuestionCard from "@/modules/knowledge/components/AskQuestionCard.vue"
import AskPassageCard from "@/modules/knowledge/components/AskPassageCard.vue"
import {PERM_EXTERNAL_ASK_PAGE} from "@/modules/security/services/SecurityService";
import BackButton from "@/modules/common/components/BackButton.vue"

export default {

  name: "AskResults",
  components: {
    KnowledgeTabs, AskQuestionCard, AskPassageCard, BackButton
  },
  data () {
    return {
      question: "",
      originalQuestion: "",
      tags: [],
      questions: [],
      responses: [],
      passages: [],
      logId: "",
      allResponses: [],
      responseArrived: false,
      alerts: {
        ask: {
          error: false,
          errorMsg: ''
        }
      }
    }
  },
  methods: {
    ask(text) {
      this.responses = []
      this.allResponses = []
      this.question = text || this.question
      this.question = this.question.trim()
      AskService.ask(this.question, this.tags, this.$route.params.bot,
                     this.$route.query.spell_check? JSON.parse(this.$route.query.spell_check.toLowerCase()) : true).then(response => {
        this.useAskData(response)
        this.searchPassages(this.question, this.tags, response.data.log_id)
      }).catch(error => {
        this.showErrorAlert("There was an error trying to find an answer for your question. Try another one")
        this.resetAsk()
        console.log(error)
      })
    },
    searchOriginalQuestion() {
      this.resetAsk()
      AskService.ask(this.originalQuestion, [], this.$route.params.bot, false).then(response => {
        this.question = this.originalQuestion
        this.useAskData(response)
        this.searchPassages(this.question, this.tags, response.data.log_id)
      }).catch(error => {
        console.log(error)
      })
    },
    searchPassages(question, tags, logId) {
      AskService.librarySearch(question, tags, logId, this.$route.params.bot).then(response => {
        this.passages = response.data
        this.sortAllResponses()
        this.responseArrived = this.passages.length != 0
        if(!this.responseArrived) {
          this.showErrorAlert("We couldn't find an answer for your question. Try another one")
          this.responseArrived = true
        }
      }).catch(error => {
        console.log(error)
        this.showErrorAlert("There was an error trying to find an answer for your question. Try another one")
      })
    },
    useAskData(response) {
      let data = response.data
      if (data.original_question) {
        this.question = data.question
        this.originalQuestion = data.original_question
      } else {
        this.originalQuestion = null
      }
      this.logId = data.log_id
      this.responses = data.questions || []
      this.responseArrived = this.responses.length != 0
    },
    sortAllResponses() {
      let allResponses = []
      allResponses = this.responses

      // to allow sort q&a and passages together
      this.passages.forEach(result => {
        result.score = result.passages[0].score
      })

      allResponses = allResponses.concat(this.passages)
      allResponses.sort((a, b) => {return b.score - a.score})

      this.allResponses = allResponses
    },
    resetAsk() {
      this.responseArrived = false
      this.question = ''
      this.tags = []
      this.responses = []
      this.allResponses = []
      this.passages =[]
    },
    goTo(route) {
      if(this.isPermExternalPage && (this.$store.getters.getInstanceAvatarDestinationUrl !== null)) {
        tools.goToExternalUrl(this.$store.getters.getInstanceAvatarDestinationUrl)
      } else if(this.$route.name.toLowerCase() == route) {
        this.resetAsk()
      } else {
        this.$router.push(`/${this.$store.getters.getInstance}/my-apps/knowledge/${route}`)
      }
    },
    showErrorAlert(message) {
      this.alerts.ask.errorMsg = message
      this.alerts.ask.error = !this.alerts.ask.error
    }
  },
  computed: {
    is_mobile() {
      const isMobile = window.matchMedia("only screen and (max-width: 1024px)")
      return isMobile.matches ? true : false
    },
    botPermissions() {
      return this.$store.getters.getInstancePermissions
    },
    isPermExternalPage() {
      return this.botPermissions.indexOf(PERM_EXTERNAL_ASK_PAGE) > -1
    }
  },
  created() {
    if(this.$route.query.question || this.$route.query.tags_ask.length > 0){
      this.question = this.$route.query.question
      this.tags = this.$route.query.tags_ask? this.$route.query.tags_ask.split(","):[];
    }
    if(this.$route.query.question && this.$route.query.spell_check=='false'){
      this.originalQuestion = this.$route.query.question
    }
    this.ask()
  }
}
</script>

<style lang="scss" scoped>

  @media (min-width: 320px) and (max-width: 767px) {

    .knowledge-card {
      width: 100%;
    }

  }

  .ask-question-margin-search {
    margin-left: 680px;
  }

  .ask-question-back-button {
    margin-left: -15px;
    margin-bottom: 20px !important;
  }

  .ask-question-result-margin {
    padding: 29px 25px;
  }

  ::v-deep {

    p.answer-links table.table.table-bordered {
      table-layout: fixed;
    }

    @media (min-width: 320px) and (max-width: 1024px) {

      .myproj-container ul.nav.nav-tabs li a.nav-link {
        width: 90px;
        display: flex;
        justify-content: center;
        margin-right: unset;
      }

      .myproj-container ul.nav.nav-tabs li.nav-item:first-of-type {
        margin-left: unset;
      }

      .margin-knowledge-container {
        margin: 0px;
        padding: 20px;
      }

      .ask-question-margin-search, .ask-question-back-button {
        margin-left: unset;
      }

      .ask-question-result-margin {
        padding: unset;
      }

      .ask-question-margin-search {
        display: none;
      }

      .mobile-preloader {
        justify-content: flex-end;
      }

      .knowledge-lowerrow {
        padding: 21px 25px;

        img {
          margin: 0.5rem auto 0rem auto;
        }
      }

      .navbar-brand {
        margin-right: 0rem;
      }
    }

  }


</style>