<template>
  <b-row class="knowledge-tabs">
    <b-col>
      <b-tabs v-model="index" content-class="mt-3">
        <b-tab @click="goToAsk()">
          <template v-slot:title>
            <img :src="'/assets/images/icon_knowledge_ask.svg'"/><span> {{ $t('myApps.knowledge.askQuestion') }} </span>
          </template>
        </b-tab>
        <b-tab v-if="canViewQA" @click="goToQa()">
          <template v-slot:title>
            <img :src="'/assets/images/icon_knowledge_qapair.svg'"/><span> {{ $t('myApps.knowledge.qaPair') }} </span>
          </template>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import {PERM_VIEW_QA} from "@/modules/security/services/SecurityService";
import tools from "@/modules/common/utils/tools";

export default {

  name: 'KnowledgeTabs',

  data () {
    return {
      index: 0,
      definedTabs: ['ask', 'questions'],
    }
  },
  props: {
    tabName: String,
    resolveRouteOutside: Function
  },
  methods: {
    goToQa() {
      this.$router.push(`/${this.$store.getters.getInstance}/my-apps/knowledge/questions`).catch(() => {})
    },
    goToAsk() {
      let url = this.$store.getters.getInstanceAvatarDestinationUrl
      if (url == undefined) {
        url = `/${this.$store.getters.getInstance}/my-apps/knowledge/ask`
        this.$router.push(url)
      } else {
        if(url.charAt(0) === "/"){
          if(this.$route.fullPath !== url){
            this.$router.push(url)
          }
        } else {
          tools.goToExternalUrl(url)
        }
      }
    }
  },
  created() {
    if (this.tabName) {
      this.index = this.definedTabs.indexOf(this.tabName)
    }
  },
  computed: {
    botPermissions() {
      return this.$store.getters.getInstancePermissions
    },
    canViewQA() {
      return this.botPermissions.indexOf(PERM_VIEW_QA) > -1
    }
  }
}
</script>

<style lang="scss">
</style>