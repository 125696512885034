import Repository from '@/modules/common/utils/Repository.js'
import store from "@/store"

export default {
  ask(question, tags, bot, spellCheck=true) {
    return Repository.dbService.post('ask/search', {
      bot_id: bot,
      text: question,
      tags: tags,
      spell_check: spellCheck,
      locale: localStorage.getItem("locale")
    })
  },
  librarySearch(question, tags, logId, bot) {
    return Repository.dbService.post('ask/search_passages', {
      bot_id: bot,
      text: question,
      tags: tags,
      log_id: logId,
      locale: localStorage.getItem("locale")
    })
  },
  getQuestions(question,botName) {
    return Repository.dbService.post('ask/inputs_typeahead', {
      q: question
    }, {
     params: {
      'bot_name': botName
    }
  })
  },
  storeNote(text, type, logId, inputId, responseId="") {
    let noteData = {
      log_id: logId,
      input_id: inputId,
      response_id: responseId,
      text: text,
      type: type
    }
    return Repository.dbService.post('ask/store_note', noteData, {
      params: {
        'bot_name': store.getters.getInstance
      }
    })
  },
  deleteNote(inputId, type) {
    return Repository.dbService.delete('ask/delete_note', {
      params: {
        bot_name: store.getters.getInstance,
        input_id: inputId,
        note_type: type
      }
    })
  },
  store_reaction(reactionData) {
    return Repository.dbService.post('ask/store_reaction', reactionData, {
      params: {
        'bot_name': store.getters.getInstance
      }
    })
  },
  store_comment(reactionData) {
    return Repository.dbService.post('ask/store_comment', reactionData, {
      params: {
        'bot_name': store.getters.getInstance
      }
    })
  },
  sendAudioForRecognition(blobObj) {
    let formData = new FormData()
    formData.append('audio', blobObj)
    let headers = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    return Repository.dbService.post('tools/recognize_voice', formData, headers)
  }
}