<template>
  <div class="d-flex back-button-span">
    <a @click="goBack">
      <img :src="'/assets/images/icon_back.svg'"/>
      <span v-if="title">{{ $t('general.back') }} {{title}}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    title: String
  },
  methods: {
    goBack() {
      this.$router.back()
    }
  },
  computed: {
    is_mobile() {
      const isMobile = window.matchMedia("only screen and (max-width: 1439px)")
      return isMobile.matches ? true : false
    },
  }
}
</script>

<style lang="scss" scoped>

  .back-button-span {
    margin-left: -15px;
    margin-bottom: 20px;
  }

  .back-button-span a {
    margin-top: 0px !important;
  }

  .cards-description{
      min-height: 48px;
  }

</style>
