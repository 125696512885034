<template>
  <b-row>
    <div>
      <b-card class="mb-2 myproj-card knowledge-card">
        <b-row class="d-flex knowledge-upperrow">
          <div class="d-flex align-items-center library-card-tag">
            <img :src="'/assets/images/icon_knowledge_library.svg'"/>
            <span>{{ $t('knowledge.ask.passageCard.type')}}</span>
          </div>
          <span v-if="data.user" class="library-card-date openSans-400 fz-12 mx-2">By {{ data.user }} </span>
          <span v-if="data.updated" class="library-card-date openSans-400 fz-12 mx-2">Updated {{ data.updated }} </span>
          <div class="knowledge-content-tags">
            <img :id="passageData.id" @click="openNoteEditor" class="cursor-pointer" :src="`/assets/images/${bookmarkIcon}.svg`"/>
            <img @click="copyUrl(data.doc_id, data.passages[0].serial)" class="cursor-pointer library-document-action-icon" src="/assets/images/icon_share.svg"
                 v-b-popover.hover.bottom="copyMessage" v-if="canCopyLink"/>
          </div>
        </b-row>

        <div class="knowledge-content-container ta-left">
          <h3 class="openSans-600 fz-16">{{data.question}}</h3>
          <p v-for="passage in data.passages" :key="passage.id" class="openSans-400 fz-16 answer-links" v-html="passage.html"></p>
        </div>

        <div class="knowledge-content-container justify-content-between ta-left margin-thumbs">
          <div>
            <img  class="cursor-pointer margin-thumbs-up" :class="{'thumbs-up-click': highlightThumbsUp}" @click="thumbs_up" :src="`/assets/images/user_activity_upvote.svg`"/>
            <img  class="cursor-pointer" :class="{'thumbs-frown-click': highlightThumbsDown}" @click="thumbs_down" :src="`/assets/images/user_activity_downvote.svg`"/>
          </div>
          <b-alert
              class="text-left"
              :show="dismissCountDown"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged">
            Feedback sent
          </b-alert>
        </div>

        <div v-if="!openBoxFeedback" class="padding-comment">
          <b-row class="justify-content-end mx-0 margin-title-comment">
            <img class="cursor-pointer" @click="closeComment" :src="'/assets/images/icon_close_popover.svg'"/>
          </b-row>
          <b-form-textarea  class="openSans-100 fz-15 input-margin note-popover-textarea margin-txtarea"
            v-model="commentText"
            placeholder='Thank you for helping to train the AI. To provide more information, please enter feedback below.'>
          </b-form-textarea>
          <div class="d-flex justify-content-end margin-button-note-popover">
            <button type="button" class="btn openSans-600 fz-12 ta-center button-secondary btn-secondary btn-md" @click="saveComment">
              Send feedback
            </button>
          </div>
        </div>

        <b-row class="knowledge-lowerrow cursor-pointer" @click="goToPassage(data.doc_id, data.passages[0].serial)">
          <div class="library-document-bottom-file openSans-400 fz-12">{{data.title}}</div>
          <img class="knowledge-book" :src="'/assets/images/icon_book.svg'"/>
        </b-row>
      </b-card>

      <div class="knowledge-tags-container ml-0 mb-4" v-if="is_mobile">
        <div class="knowledge-tags-title-container"><span class="openSans-400 fz-12">Tags:</span></div>
        <span class="openSans-400 fz-12 knowledge-tags" v-for="tag in data.all_answers_tags" :key="tag">{{ tag }}</span>
      </div>

    </div>

    <div class="knowledge-tags-container" v-if="is_mobile == false" >
      <div class="knowledge-tags-title-container"><span class="openSans-400 fz-12">Tags:</span></div>
      <span class="openSans-400 fz-12 knowledge-tags" v-for="tag in data.passages[0].tags" :key="tag">{{ tag }} </span>
    </div>

    <note-popup
      :target="passageData.id"
      type="passage"
      :note-data="passageData.note"
      :log-id="logId"
      :input-id="passageData.id"
      :response-id="''"
      :callback="setBookmarkIcon"></note-popup>

  </b-row>
</template>

<script>
import tools from "@/modules/common/utils/tools.js"
import {PERM_OVER_CONTENT_PROTECT} from "@/modules/security/services/SecurityService";
import NotePopup from "@/modules/knowledge/components/NotePopup.vue"
import AskService from "@/modules/knowledge/services/AskService";

const reactionThumbsUp = 'smile';
const reactionThumbsDown = 'frown';

export default {
  name: 'AskPassageCard',
  components: {
    NotePopup
  },
  props: {
    data: Object,
    logId: String,
    textSearched: String
  },
  data () {
    return {
      passageData: {
        id: 'default-response-id',
        note: {}
      },
      bookmarkIcon: "icon_bookmark",
      copyMessage: this.$t('knowledge.ask.popover.copyLink'),
      openBoxFeedback: true,
      commentText: '',
      dismissSecs: 7,
      dismissCountDown: 0,
      highlightThumbsUp: false,
      highlightThumbsDown: false,
      fromSendUp: true,
      fromSendFrown: true
    }
  },
  methods: {
    openNoteEditor() {
      this.closeAllEditors()
    },
    closeAllEditors() {
      this.$root.$emit('bv::hide::popover')
    },
    setBookmarkIcon(iconName) {
      this.bookmarkIcon = iconName
    },
    goToPassage(docId, passageNum) {
      let passageUrl = `/${this.$store.getters.getInstance}/document/${ docId }#passage_${ passageNum }`
      window.open(passageUrl)
    },
    copyUrl(docId, passageNum) {
      tools.copyToClipboard(`${window.location.origin}/${this.$store.getters.getInstance}/document/${ docId }#passage_${ passageNum }`)
      this.copyMessage = this.$t('knowledge.ask.popover.copied')
    },
    thumbs_up() {
      if(this.fromSendUp) {
        this.store_reaction(reactionThumbsUp)
        this.fromSendUp = false
      }
      this.highlightThumbsUp = true
      this.highlightThumbsDown = !this.highlightThumbsUp
    },
    thumbs_down() {
      this.openBoxFeedback = !this.openBoxFeedback

      this.highlightThumbsDown = true
      this.highlightThumbsUp = !this.highlightThumbsDown
    },
    store_reaction(reactionType) {
      const data = {
        input_text: this.textSearched,
        log_id: this.logId,
        reaction_type: reactionType,
        passage_id: this.passageData.id
      }
      AskService.store_reaction(data).catch(error => { console.log(error) })
    },
    saveComment() {
      this.store_comment()
    },
    store_comment() {
      this.commentText = this.commentText.trim()
      if (this.commentText === '') {
        this.setFromSendFrown()
        this.closeComment()
        return;
      }
      const data = {
        input_text: this.textSearched,
        log_id: this.logId,
        comment: this.commentText,
        passage_id: this.passageData.id
      }
      AskService.store_comment(data).then(response => {
        this.setFromSendFrown()
        if (response.data.error === 0) {
          this.closeComment()
          this.showAlert()
          this.commentText = ''
        }
      })
    },
    closeComment() {
      this.openBoxFeedback = !this.openBoxFeedback
      if (this.highlightThumbsDown && this.fromSendFrown) {
        this.setFromSendFrown()
      }
    },
    setFromSendFrown() {
      if (this.fromSendFrown) {
        this.store_reaction(reactionThumbsDown);
        this.fromSendFrown = false
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    updateCardData() {
      if (this.data.passages.length != 0) {
        let passage = this.data.passages[0]
        this.passageData.id = passage.id
        this.passageData.note = passage.note || {}
      } else {
        // in theopry should always have one passage
        this.passageData.id = ''
        this.passageData.note = {}
      }
      if (this.passageData.note.text) {
        this.bookmarkIcon = "icon-bookmark-active"
      } else {
        this.bookmarkIcon = "icon_bookmark"
      }
    }
  },
  computed: {
    is_mobile() {
      const isMobile = window.matchMedia("only screen and (max-width: 1439px)")
      return isMobile.matches ? true : false
    },
    botPermissions: function() {
      return this.$store.getters.getInstancePermissions
    },
    canCopyLink: function() {
      return this.botPermissions.indexOf(PERM_OVER_CONTENT_PROTECT) > -1
    }
  },
  created() {
    this.updateCardData()
  },
  watch: {
    data() {
      this.updateCardData()
    }
  },

}
</script>

<style lang="scss" scoped>

  @media (min-width: 1440px) and (max-width: 2559px) {

    .knowledge-tags-container {
      max-width: 240px;
    }

  }

  @media (min-width: 320px) and (max-width: 1439px) {

    .knowledge-card {
      width: 100% !important;
      margin-bottom: 16px !important;
    }

    .knowledge-tags-container {
      margin-bottom: 32px !important;
    }
    

  }


  div.popover::v-deep {
    border: 1px solid var(--viridian);

    div.popover-body {
      color: var(--viridian) !important;
      padding: 0.5rem 0.75rem;
      min-height: 37px;
      text-align: center;
    }

  }

  .knowledge-review-input {
    width: 100%;
    margin: 20px 0px;
    min-height: 88px;
    height: auto;
    background-color: #f8f8f8;
    border: 1px solid #dcdcdc;
  }

  .knowledge-book {
    margin-left: 19px;
  }

  .knowledge-checkmark {
    margin: 0px 15px 0px 22px;
  }

  .knowledge-lowerrow {
    padding: 21px 25px 21px 40px;
    background-color: #fafbfc;
    border-top: 1px solid #e3eef8;
    margin: 0px;
  }

  .knowledge-upperrow {
    margin: 18px 0px 16px 0px !important;
  }

  .knowledge-content-tags {
    margin: 0px 29px 0px auto;
  }

  .library-card-tag-qa {
    background-color: rgba(45, 184, 39, 0.16);
  }

  .knowledge-content-container {
    padding: 0px 50px 0px 40px
  }

  .knowledge-tags-title-container {
    width: 100%;
    text-align: left;
    margin-left: 10px;
  }

  .knowledge-card {
    width: 1000px;
    margin-bottom: 50px;
  }

  .cards-description{
    min-height: 48px;
  }

  .margin-thumbs {
    margin-bottom: 10px;
  }

  .margin-thumbs-up {
    margin-right: 16px;
  }

  .padding-comment {
    padding: 0 20px 20px;
  }

  .margin-title-comment {
    padding-bottom: 15px;
  }

  .margin-txtarea {
    margin-bottom: 16px;
  }

  .thumbs-up-click {
    filter: invert(41%) sepia(80%) saturate(4758%) hue-rotate(199deg) brightness(100%) contrast(110%);
  }

  .thumbs-frown-click {
    filter: invert(41%) sepia(80%) saturate(4758%) hue-rotate(199deg) brightness(100%) contrast(110%);
  }
</style>